import React, { useEffect, useRef } from 'react';
import { styled, Card, Typography, Box, Divider, useTheme, Avatar } from '@mui/material';

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import html from 'highlight.js/lib/languages/xml'; // Highlight.js trata HTML como XML
import css from 'highlight.js/lib/languages/css';
import java from 'highlight.js/lib/languages/java';
import csharp from 'highlight.js/lib/languages/csharp';
import bash from 'highlight.js/lib/languages/bash';
import python from 'highlight.js/lib/languages/python';
import typescript from 'highlight.js/lib/languages/typescript';
import json from 'highlight.js/lib/languages/json';

import 'highlight.js/styles/tokyo-night-dark.css';
import { trataData } from '../../../shared/utils';

import image from '../../../shared/images/P_20240822_133831_BF.jpg';

interface IContentPostagem {
    title: string;
    content: string;
    date: Date;
    nameCreator: string;
}

export const ContentPostagem: React.FC<IContentPostagem> = ({ title, content, date, nameCreator }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('php', php);
    hljs.registerLanguage('html/xml', html);
    hljs.registerLanguage('markup', html);
    hljs.registerLanguage('css', css);
    hljs.registerLanguage('java', java);
    hljs.registerLanguage('csharp', csharp);
    hljs.registerLanguage('bash', bash);
    hljs.registerLanguage('python', python);
    hljs.registerLanguage('typescript', typescript);
    hljs.registerLanguage('json', json);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block as HTMLElement);
            });
        }
    }, [content]);

    const AvatarPostagem = styled(Avatar)(({ theme }) => ({
        width: 48,
        height: 48,
        cursor: 'pointer',
        transition: 'opacity 1s, border 2.5s',
        '&:hover': {
            backgroundPosition: 'right bottom',
            opacity: 0.6,
        },
    }));

    const theme = useTheme();

    const BoxPostagem = styled(Box)(({ theme }) => ({
        padding: '2% 3%',
    }));

    const htmlStyleReset = {
        code: { whiteSpace: 'break-spaces', fontSize: "large", border: 2, borderRadius: 0.8, borderColor: "#fc6005"},
        img: {
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            '@media (max-width: 1150px)': {
                maxWidth: '100%',
                height: 'auto',
            },
        },
    };

  return (
        <>
            <Card>
                <BoxPostagem>
                    <Typography className="TypoSubtitle" width={'65%'} fontFamily={'Source Serif 4, serif'} fontSize={'clamp(0.7rem, 0.55vw + 0.9rem, 1.4rem)'} color={theme.palette.text.secondary} sx={{ '@media (max-width: 900px)': { width: '100%' }}}>{title}</Typography>
                </BoxPostagem>

                <Divider sx={{ width: '94%', margin: '0 auto', background: `${theme.palette.secondary.main}`}}/>

                <BoxPostagem sx={{ paddingTop: 1 }}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <AvatarPostagem src={image} sx={{ marginRight: 1, border: 1, borderRadius: 50, borderColor: `${theme.palette.secondary.main}`}}/>
                        <Typography fontSize={'clamp(0.7rem, 0.55vw + 0.7rem, 1.1rem)'} fontWeight={'bold'} sx={{ cursor: 'pointer' }}>{nameCreator}</Typography>
                    </Box>
                    
                    <Typography variant="body2">Postado: {trataData(date)} </Typography>
                </BoxPostagem>
            </Card>

            <Card sx={{ margin: '2% 0' }}>
                <BoxPostagem ref={contentRef}>
                    <Typography dangerouslySetInnerHTML={{ __html: content }} fontFamily={'Merriweather, serif'} fontWeight={300} fontSize={'clamp(0.7rem, 0.5vw + 0.65rem, 1.15rem)'} color={theme.palette.text.primary} sx={{ ...htmlStyleReset }}/>
                </BoxPostagem>
            </Card>
        </>
    );
};